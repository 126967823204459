<script>
import axios from "axios";
import L from 'leaflet';
import "leaflet.markercluster/dist/leaflet.markercluster.js";
import mapFunctions from "./map.js";

const api = axios.create({
    baseURL: "/api/",
    withCredentials: true,
    xsrfCookieName: "csrftoken",
    xsrfHeaderName: "X-CSRFToken"
});

export default {
    delimiters: ["[[", "]]"],
    data() {
        return {
            actions: [],
            loading: false,
            leftTopLat: null,
            leftTopLon: null,
            rightBottomLat: null,
            rightBottomLon: null,
            total_count: 0,
            showLimit: 10,
            showLimitChange: 10,
            searchOnMove: false,

            // filters
            filterDialogOpen: false,
            activeFilter: "",

            fulltext: "",
            fulltextModified: false,

            mine: false,
            mineLabel: "Moje",
            mineBadge: null,
            mineClass: "btn-outline-dark",
            mineModified: false,

            approve: false,
            approveLabel: "Ke schválení",
            approveBadge: null,
            approveClass: "btn-outline-dark",
            approveModified: false,

            hidden: false,
            hiddenLabel: "Skryté akce",
            hiddenBadge: null,
            hiddenClass: "btn-outline-dark",
            hiddenModified: false,

            allCityParts: [],
            cityPart: [],
            cityPartLabel: "Městská část",
            cityPartBadge: null,
            cityPartClass: "btn-outline-dark",
            cityPartModified: false,

            allInvestors: [],
            investor: [],
            investorLabel: "Investor",
            investorBadge: null,
            investorClass: "btn-outline-dark",
            showOtherInvestors: false,
            investorModified: false,

            allTypes: [],
            type: [],
            typeLabel: "Typ akce",
            typeBadge: null,
            typeClass: "btn-outline-dark",
            typeModified: false,

            allStates: [],
            state: [],
            stateLabel: "Stav akce",
            stateBadge: null,
            stateClass: "btn-outline-dark",
            stateModified: false,

            dateBadge: null,
            dateLabel: "Termín akce",
            dateClass: "btn-outline-dark",
            dateFrom: null,
            dateTo: null,
            dateModified: false,

            delayBadge: null,
            delayLabel: "Zdržení",
            delayClass: "btn-outline-dark",
            delay: [],
            delayModified: false,

            exportLabel: "Exportovat",

            // helpers used to handle request
            cancelToken: null,
            requestRunning: false,
            clickTimer: null,

            fullScreen: false,

            bigMap: null,
            bigMapMarkerLayer: null,
            bigMapRedraw: true,
            bigMapMarkers: {},
            bigMapPoints: [],

            complexFilters: false,
            complexFiltersLabel: "Pokročilé filtrování",
            simpleFiltersLabel: "Základní filtrování",

            // base filters set as whole dataset
            baseFilters: {},

            getIcon: null,
            mapConfig: null,
            addGeometryToMap: null,
            setZoomAndCenter: null
        }
    },
    created() {
        if (window.location.href.includes("closure=True")) {
            this.closure = true;
        }

        this.loadFilters();
        // add all data to base filters
        this.baseFilters = { ...this._.data };

        this.setZoomAndCenter = mapFunctions.setZoomAndCenter;
        this.addGeometryToMap = mapFunctions.addGeometryToMap;
        this.mapConfig = mapFunctions.mapConfig;
        this.getIcon = mapFunctions.getIcon;
        setInterval(function () {
            if (this.elementIsVisibleInViewport()) {
                this.updateShowLimit();
            }
        }.bind(this), 200)

    },
    computed: {
        modifiedFilters: function () {
            return this.dateModified || this.typeModified || this.stateModified
                || this.mineModified || this.cityPartModified || this.delayModified
                || this.investorModified || this.fulltextModified || this.hiddenModified
                || this.approve;
        },
    },
    watch: {
        fulltext: function (val) {
            if (val == this.baseFilters.fulltext) {
                this.fulltextModified = false;
            } else {
                this.fulltextModified = true;
            }
            this.getNewData(false);
        },
        state: function (val) {
            if (val.length > 0) {
                this.stateClass = "btn-primary";
                this.stateBadge = val.length;
            } else {
                this.stateClass = "btn-outline-dark";
                this.stateBadge = null;
            }
            this.stateModified = !this.arraysEqual(val, this.baseFilters.state);
        },
        delay: function (val) {
            if (val.length > 0) {
                this.delayClass = "btn-primary";
                this.delayBadge = val.length;
            } else {
                this.delayClass = "btn-outline-dark";
                this.delayBadge = null;
            }
            this.delayModified = !this.arraysEqual(val, this.baseFilters.delay);
        },
        mine: function (val) {
            if (this.mine) {
                this.mineClass = "btn-primary";
                this.mineModified = true;
            } else {
                this.mineClass = "btn-outline-dark";
                this.mineModified = false;
            }
            this.getNewData(false);
        },
        approve: function (val) {
            if (this.approve) {
                this.approveClass = "btn-primary";
                this.approveModified = true;
            } else {
                this.approveClass = "btn-outline-dark";
                this.approveModified = false;
            }
            this.getNewData(false);
        },
        hidden: function (val) {
            if (this.hidden) {
                this.hiddenClass = "btn-primary";
                this.hiddenModified = true;
            } else {
                this.hiddenClass = "btn-outline-dark";
                this.hiddenModified = false;
            }
            this.getNewData(false);
        },
        dateTo: function (val) {
            if (this.dateTo || this.dateFrom) {
                this.dateClass = "btn-primary";
                this.mineModified = true;
            } else {
                this.dateClass = "btn-outline-dark";
                this.mineModified = false;
            }
        },
        dateFrom: function (val) {
            if (this.dateTo || this.dateFrom) {
                this.dateClass = "btn-primary";
                this.mineModified = true;
            } else {
                this.dateClass = "btn-outline-dark";
                this.mineModified = false;
            }
        },
        cityPart: function (val) {
            if (val.length > 0 && val.length < 3) {
                this.cityPartClass = "btn-primary";
                this.cityPartBadge = null;
                this.cityPartLabel = "Praha " + val.join(", ");
            } else if (val.length > 2) {
                this.cityPartClass = "btn-primary";
                this.cityPartBadge = val.length;
                this.cityPartLabel = "Městská část";
            } else {
                this.cityPartClass = "btn-outline-dark";
                this.cityPartLabel = "Městská část";
                this.cityPartBadge = null;
            }
            this.cityPartModified = !this.arraysEqual(val, this.baseFilters.cityPart);
        },
        investor: function (val) {
            if (val.length > 0) {
                this.investorClass = "btn-primary";
                this.investorBadge = val.length;
            } else {
                this.investorClass = "btn-outline-dark";
                this.investorBadge = null;
            }
            this.investorModified = !this.arraysEqual(val, this.baseFilters.investor);
        },
        type: function (val) {
            if (val.length > 0) {
                this.typeClass = "btn-primary";
                this.typeBadge = val.length;
            } else {
                this.typeClass = "btn-outline-dark";
                this.typeBadge = null;
            }
            this.typeModified = !this.arraysEqual(val, this.baseFilters.type);
        },
        activeFilter: function (val) {
            var element = document.getElementById("navbar_header");
            if (val == "investor" || val == "cityPart" || val == "state" || val == "type"
                || val == "date" || val == "delay") {
                element.classList.add("d-none");
                element.classList.add("d-md-inline");
                element.classList.remove("d-inline");
            }
            else {
                element.classList.remove("d-none");
                element.classList.remove("d-md-inline");
                element.classList.add("d-inline");
            }
        },
        actions: function (val) {
            this.loading = true;
            this.bigMapMarkers = {};
            if (val != null) {
                if (this.bigMap == null) {
                    this.drawMap();
                }

                this.actions = val;
                this.addActionsToMap(this.actions);
            }
        },
        fullScreen: function (val) {
            this.redrawMap();
        }
    },
    methods: {
        elementIsVisibleInViewport: function () {
            if (this.$refs && this.$refs.reloadLine) {
                const { top, left, bottom, right } = this.$refs.reloadLine.getBoundingClientRect();
                var innerWidth  = window.innerWidth;
                var innerHeight = window.innerHeight;
                return top >= 0 && left >= 0 && bottom <= innerHeight && right <= innerWidth;
            }
            else {
                return false;
            }
        },
        updateShowLimit: function () {
            if (this.showLimit > this.total_count) {
                return
            }
            if (this.showLimit + this.showLimitChange > this.total_count) {
                this.showLimit = this.total_count;
            }
            else {
                this.showLimit += this.showLimitChange;
            }
        },
        arraysEqual: function (a, b) {
            if (a === b) return true;
            if (a == null || b == null) return false;
            if (a.length !== b.length) return false;

            // If you don't care about the order of the elements inside
            // the array, you should sort both arrays here.
            // Please note that calling sort on an array will modify that array.
            // you might want to clone your array first.

            for (var i = 0; i < a.length; ++i) {
                if (a[i] !== b[i]) return false;
            }
            return true;
        },
        getNewData: function (nowait) {
            if (this.filterDialogOpen) {
                return;
            }
            // mark request as running
            this.requestRunning = true;

            // check whether there is call to server already planned
            if (this.clickTimer) {
                clearTimeout(this.clickTimer);
            }

            // check whether there is a call to server running
            // if so, cancel the request, new one will be planned instead
            if (this.cancelToken != null) {
                this.cancelToken.cancel("Operation canceled by the user.");
                this.cancelToken = null;
            }

            if (!nowait) {
                // plan new call to server in 700ms
                var self = this;
                this.clickTimer = setTimeout(function () {
                    self.readDataFromServer();
                }, 500);
            } else {
                this.readDataFromServer();
            }
        },
        loadFilters: function () {
            api.get("/filters").then(function (response) {
                var data = response.data;
                this.allCityParts = data.city_parts;
                this.allInvestors = data.main_investors;
                this.allInvestors.push(...data.other_investors);
                for (var i = 0; i < data.types.length; i++) {
                    this.allTypes.push(data.types[i].name)
                }
                for (var i = 0; i < data.states.length; i++) {
                    if (data.states[i].checked) {
                        this.state.push(data.states[i].name);
                        this.stateBadge = this.state.length;
                        this.stateClass = "btn-primary";
                    }
                    this.allStates.push(data.states[i].name)
                }
                this.allDelays = data.delays;
                this.getNewData(true);
            }.bind(this));
        },
        readDataFromServer: function () {
            this.loading = true;
            const vm = this;

            // cancle already running request
            if (this.cancelToken != null) {
                this.cancelToken.cancel("Operation canceled by the user.");
            }

            // initiate cancel token used to identify, whether ther is a request running already
            this.cancelToken = axios.CancelToken.source();

            api
                .get(this.buildQuery())
                .then(function (response) {
                    vm.actions = response.data.data;
                    vm.total_count = response.data.count;
                    vm.showLimit = vm.showLimitChange;

                    vm.cancelToken = null;
                    vm.loading = false;
                    vm.scrollToListTop();
                })
                .catch(function (e) {
                    if (axios.isCancel(e)) {
                        console.log("Request canceled", e.message);
                    } else {
                        console.log("Actions retrieval failed");
                        console.log(e);
                    }
                    vm.loading = false;
                });
        },
        buildQuery: function () {
            var query = "/action?";
            query += this.buildFilters();
            return query;
        },
        buildDownloadQuery: function () {
            var query =
                "/api/action?"
            query += this.buildFilters().slice(1);
            query += "&format_output=csv"
            return query;
        },
        buildFilters: function () {
            var query = "";
            if (this.searchOnMove && this.leftTopLon != null) {
                query += "&lon__gt=" + this.leftTopLon;
            }
            if (this.searchOnMove && this.leftTopLat != null) {
                query += "&lat__lt=" + this.leftTopLat;
            }
            if (this.searchOnMove && this.rightBottomLon != null) {
                query += "&lon__lt=" + this.rightBottomLon;
            }
            if (this.searchOnMove && this.rightBottomLat != null) {
                query += "&lat__gt=" + this.rightBottomLat;
            }
            if (this.fulltext != null) {
                query += "&fulltext=" + this.fulltext;
            }
            if (this.cityPart != null) {
                query += "&cityPart=" + this.cityPart;
            }
            if (this.investor != null) {
                query += "&investor=" + this.investor;
            }
            if (this.type != null) {
                query += "&type=" + this.type;
            }
            if (this.state != null) {
                query += "&state=" + this.state;
            }
            if (this.delay != null) {
                query += "&delay=" + this.delay;
            }

            if (this.dateFrom) {
                query += "&date_from=" + this.dateFrom;
            }

            if (this.dateTo) {
                query += "&date_to=" + this.dateTo;
            }

            if (this.mine) {
                query += "&mine=true";
            }

            if (this.hidden) {
                query += "&hidden_action=true";
            }

            if (this.approve) {
                query += "&to_approve=true";
            }

            return query;
        },
        mapPositionUpdated: function (coords) {
            if (this.searchOnMove) {
                this.leftTopLon = coords[0]["x"];
                this.leftTopLat = coords[0]["y"];
                this.rightBottomLon = coords[1]["x"];
                this.rightBottomLat = coords[1]["y"];
                this.getNewData(false);
            }
        },
        activateFilter: function (val) {
            if (val == this.activeFilter) {
                this.activeFilter = "";
                this.filterDialogOpen = false;
                this.getNewData(true);
                document.body.classList.remove("no-scroll");

            } else {
                this.activeFilter = val;
                this.filterDialogOpen = true;
                document.body.classList.add("no-scroll");
            }
        },
        deactivateFilters: function () {
            this.activeFilter = null;
            this.filterDialogOpen = false;
            this.getNewData(true);
            document.body.classList.remove("no-scroll");
        },
        resetFilters: function () {
            this.fulltext = this.baseFilters.fulltext;
            this.cityPart = this.baseFilters.cityPart;
            this.investor = this.baseFilters.investor;
            this.leftTopLon = null;
            this.leftTopLat = null;
            this.rightBottomLon = null;
            this.rightBottomLat = null;
            this.state = this.baseFilters.state;
            this.mine = this.baseFilters.mine;
            this.dateTo = this.baseFilters.dateTo;
            this.dateFrom = this.baseFilters.dateFrom;
            this.type = this.baseFilters.type;
            this.delay = this.baseFilters.delay;
            this.hidden = this.baseFilters.hidden;
            this.approve = this.baseFilters.approve;
            this.filterDialogOpen = false;
            this.getNewData(true);
        },
        scrollToListTop: function () {
            window.scrollTo(0, 0);
        },
        selectAllCityParts: function () {
            this.cityPart = this.allCityParts;
        },
        deselectAllCityParts: function () {
            this.cityPart = [];
        },
        selectAllInvestors: function () {
            this.investor = this.allInvestors;
        },
        deselectAllInvestors: function () {
            this.investor = [];
        },
        deselectDates: function () {
            this.dateFrom = null;
            this.dateTo = null;
        },
        selectAllTypes: function () {
            this.type = this.allTypes;
        },
        deselectTypes: function () {
            this.type = [];
        },
        selectAllStates: function () {
            this.state = this.allStates;
        },
        deselectStates: function () {
            this.state = [];
        },
        selectAllDelays: function () {
            this.delay = this.allDelays;
        },
        deselectDelays: function () {
            this.delay = [];
        },
        showOtherInvestorsToggle: function () {
            this.showOtherInvestors = !this.showOtherInvestors;
        },
        drawMap: function () {
            // init map
            if (this.m) {
                this.m.remove();
            }
            this.m = L.map("big_map_holder", {
                center: [50.074427024615723, 14.438710255616163],
                zoom: 11,
                attributionControl: false
            });
            const SMAPY_API_KEY = 'QZ5NxR4tMu5bIpL4IOBPBoHd3s6IVvnURdErGGIVr0U';
            L.tileLayer(`https://api.mapy.cz/v1/maptiles/basic/256/{z}/{x}/{y}?apikey=${SMAPY_API_KEY}`, {
                minZoom: 0,
                maxZoom: 19,
            }).addTo(this.m);

            var attribution = L.control.attribution({ prefix: false })
            attribution.addAttribution('<a href="https://api.mapy.cz/copyright" target="_blank">&copy; Seznam.cz a.s. a další</a>');
            attribution.addTo(this.m);

            const LogoControl = L.Control.extend({
                options: {
                    position: 'bottomleft',
                },

                onAdd: function (map) {
                const container = L.DomUtil.create('div');
                const link = L.DomUtil.create('a', '', container);

                link.setAttribute('href', 'http://mapy.cz/');
                link.setAttribute('target', '_blank');
                link.innerHTML = '<img src="https://api.mapy.cz/img/api/logo.svg" />';
                L.DomEvent.disableClickPropagation(link);

                return container;
                },
            });

            new LogoControl().addTo(this.m);

            setTimeout(function () {
                this.m.invalidateSize();
            }.bind(this), 100);
        },
        addActionsToMap: function () {
            this.loading = true;
            if (this.bigMapMarkerLayer == null) {
                this.bigMapMarkerLayer = L.markerClusterGroup(
                    {
                        showCoverageOnHover: false,
                        animate: false
                    }
                );
                this.bigMapMarkerLayer.addTo(this.m)
            } else {
                this.bigMapMarkerLayer.clearLayers();
                this.bigMapMarkerLayer = L.markerClusterGroup(
                    {
                        showCoverageOnHover: false,
                        animate: false
                    }
                );
                this.bigMapMarkerLayer.addTo(this.m)
            }

            this.bigMapPoints = [];
            for (var action of this.actions) {
                var icon = this.getIcon(action, false);

                var center = [action.lat, action.lon];
                var marker = L.marker(center, { icon: icon });
                this.bigMapMarkers[action.id] = {
                    marker: marker,
                    action: action
                };

                var startDate = "";
                if (action.dt_format == 'TIME'){
                    startDate = this.$moment(action.start).format("DD.MM.Y") + "<br>" + this.$moment(action.start).format("HH:mm");
                }
                else if (action.dt_format == 'DAY'){
                    startDate = this.$moment(action.start).format("DD.MM") + "<br>" + this.$moment(action.start).format("Y");
                }
                else if (action.dt_format == 'MONTH'){
                    startDate = this.$moment(action.start).format("MMMM") + "<br>" + this.$moment(action.start).format("Y");
                }
                var endDate = "";
                if (action.dt_format == 'TIME'){
                    endDate = this.$moment(action.end).format("DD.MM.Y") + "<br>" + this.$moment(action.end).format("HH:mm");
                }
                else if (action.dt_format == 'DAY'){
                    endDate = this.$moment(action.end).format("DD.MM") + "<br>" + this.$moment(action.end).format("Y");
                }
                else if (action.dt_format == 'MONTH'){
                    endDate = this.$moment(action.end).format("MMMM") + "<br>" + this.$moment(action.end).format("Y");
                }

                var html = `
                        <div class="card_body_inner result_card">
                        <div class="result_card_wrapper col col-12">
                            <div class="row">
                                <div class="result_card_body col col-12" v-if="action">
                                    <div class="severity_message"><div class="severity_dot %severityClass"></div>%severityText</div>
                                    <h2>%name</h2>
                                    <h3 class="result_card_sub_header">Praha %city_part<span class="ms-1">&#8226; %street</span></h3>

                                    <div class="row no-gutters">
                                        <div class="col col-12">
                                            <div class="action_start text-start">
                                                %start
                                            </div>
                                            <div class="action_arrow text-center">
                                                <img class="closure_image" src="/static/v24/img/arrow_short.png">
                                            </div>
                                            <div class="action_end text-start">
                                                %end
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <a  class="detail_link btn btn-primary" href="/action/%id" target="_blank">
                            detail
                        </a >
                    </div>
                    </div>
                    `
                    .replaceAll("%id", action.id)
                    .replaceAll("%city_part", action.city_part.join(", "))
                    .replaceAll("%severityText", this.$t("severity." + action.severity))
                    .replaceAll("%severityClass", "severity_dot_" + action.severity)
                    .replaceAll("%name", action.name)
                    .replaceAll("%state", this.$t("states." + action.state))
                    .replaceAll("%start", startDate)
                    .replaceAll("%end", endDate)
                    .replaceAll("%street", action.street);

                marker.bindPopup(html);
                marker.on('click', this.clickZoom(marker));
                this.bigMapPoints.push(center);
                this.bigMapMarkerLayer.addLayer(marker);
            }

            if (this.bigMapRedraw) {
                this.redrawMap();
            }

            this.loading = false;
            this.bigMapRedraw = true;
        },
        // currying of click event on marker
        clickZoom: function (marker) {
            return function (e) {
                marker.closePopup();
                this.m.setView(e.target.getLatLng(), 19);
                setTimeout(function () {
                    marker.openPopup();
                    this.m.invalidateSize();
                }.bind(this), 350);
            }.bind(this)
        },
        getMapBorder: function getMapBorder() {
            var bounds = this.m.getBounds();
            var leftTopCoords = bounds.getNorthWest();
            var botRightCoords = bounds.getSouthEast();
            return [leftTopCoords, botRightCoords];
        },
        highlightMarker: function (val) {
            if (val != null) {
                var marker = this.bigMapMarkers[val].marker;
                this.bigMapMarkerLayer.removeLayer(marker);

                var icon = this.getIcon(this.bigMapMarkers[val].action, true);

                var marker = L.marker(marker.getLatLng(), { icon: icon });
                this.bigMapMarkerLayer.addLayer(marker);
            }
        },
        normaliseMarker: function (val) {
            if (val != null) {
                var marker = this.bigMapMarkers[val].marker;
                this.bigMapMarkerLayer.removeLayer(marker);

                var icon = this.getIcon(this.bigMapMarkers[val].action, false);

                var marker = L.marker(marker.getLatLng(), { icon: icon });
                this.bigMapMarkerLayer.addLayer(marker);
            }
        },
        redrawMap: function (val) {
            if (this.bigMapPoints.length > 0) {
                var myBounds = new L.LatLngBounds(this.bigMapPoints);
                this.m.fitBounds(myBounds);
            } else {
                this.m.panTo(new L.LatLng(50.074427024615723, 14.438710255616163));
                this.m.setZoom(11);
            }
        },
        exportData: function () {
            window.open(this.buildDownloadQuery(), '_blank');
            // axios
            //     .get(this.buildDownloadQuery())
            //     .then(function (response) {

            //         var blobObj = new Blob([response.data], { type: response.headers["content-type"] });
            //         const href = URL.createObjectURL(blobObj);

            //         // create "a" HTML element with href to file & click
            //         const link = document.createElement('a');
            //         link.href = href;
            //         //create default name
            //         link.setAttribute('download', 'export.csv');
            //         document.body.appendChild(link);
            //         link.click();

            //         // clean up "a" element & remove ObjectURL
            //         document.body.removeChild(link);
            //         URL.revokeObjectURL(href);
            //     })
        },
        activeComplexFilters: function () {
            this.complexFilters = true;
        },
        deactiveComplexFilters: function () {
            this.complexFilters = false;
        }
    }
}
</script>

<style scoped></style>
