<script>
import mapFunctions from "./map.js";

export default {
    delimiters: ['[[', ']]'],
    props: ['id', 'action'],
    template: "#result-card-similar",
    data: function() {
        return {
            map_holder_id: null,
            zoom: 12,
            max: 0,
            ready: false,
            center: null,
            m: null,
            allPoints: [],
            isHovered: false,
            subjectSpecificClass: null,
            mapConfig: null,
        };
    },
    created: function () {
        this.drawMap = mapFunctions.drawMap;
        this.setZoomAndCenter = mapFunctions.setZoomAndCenter;
        this.addGeometryToMap = mapFunctions.addGeometryToMap;
        this.mapConfig = mapFunctions.mapConfig;
        this.getIcon = mapFunctions.getIcon;
        this.map_holder_id = "result_card_map_holder_" + this.id;
    },
    watch: {
        action: function (val) {
            if (val != null) {
                this.action = val;
                this.subjectSpecificClass = "result_card_" + val.subject;
                this.ready = true;
                this.drawMap(this.map_holder_id, [this.action.lat, this.action.lon], true, false);
            } else {
                this.ready = false;
            }
        },
    },
    methods: {
        hovered: function () {
            this.isHovered = true;
        },
        unhovered: function () {
            this.isHovered = false;
        },
        openDetail: function () {
            window.open("/action/" + this.action.id, '_blank');
        },
    }
}
</script>