import { createApp } from 'vue';
import './scss/main.scss';
import ListApp from "./ListApp.vue";
import DetailApp from "./DetailApp.vue";
import EditApp from "./EditApp.vue";
import ResultCard from './ResultCard.vue';
import i18n from "./messages";
import ResultCardSimilar from './ResultCardSimilar.vue';
import { VueEditor } from "vue3-editor";
import moment from "moment/moment";
import { Collapse } from 'bootstrap';

moment.locale('cs-CZ');

var relativeTimeFomatter = (number, withoutSuffix, key, isFuture) => {
    return i18n.global.tc(`relativeTime.${key}${isFuture ? "" : "_past"}`, number);
}

moment.updateLocale('cs-CZ', {
    months: {
        standalone: 'ledna_února_března_dubna_května_června_července_srpna_září_října_listopadu_prosince'.split('_'),
        format: 'leden_únor_březen_duben_květen_červen_červenec_srpen_září_říjen_listopad_prosinec'.split('_'),
    },
    monthsShort : 'led_úno_bře_dub_kvě_čvn_čvc_srp_zář_říj_lis_pro'.split('_'),
    relativeTime: {
        future: function (number) {
            if (number == i18n.global.t("relativeTime.d")) {
                number = i18n.global.tc("relativeTime.dd", 1)
            }

            return i18n.global.tc(`relativeTime.future`, parseInt(number), {s: number});
        },
        past: function (number) {
            if (number == i18n.global.t("relativeTime.d_past")) {
                return number
            }

            return i18n.global.t("relativeTime.past", {s: number})
        },
        s: relativeTimeFomatter,
        ss: relativeTimeFomatter,
        m: relativeTimeFomatter,
        mm: relativeTimeFomatter,
        h: relativeTimeFomatter,
        hh: relativeTimeFomatter,
        d: relativeTimeFomatter,
        dd: relativeTimeFomatter,
        M: relativeTimeFomatter,
        MM: relativeTimeFomatter,
        y: relativeTimeFomatter,
        yy: relativeTimeFomatter
    },
    longDateFormat : {
        L : 'DD.MM.YYYY',
        l : 'D. M. YYYY',
        LLL : 'D MMMM YYYY HH:mm',
        lll : 'D. M. YYYY HH:mm'
    }
})


collapse = new Collapse(document.getElementById("navbars"), {
    toggle: false
});


const list_app = createApp(ListApp).use(i18n).component("result-card", ResultCard);
list_app.config.globalProperties.$moment = moment;
list_app.mount('#list_app');
const detail_app = createApp(DetailApp).use(i18n).component("result-card", ResultCard).component("result-card-similar", ResultCardSimilar);
detail_app.config.globalProperties.$moment = moment;
detail_app.mount('#detail_app');
const edit_app = createApp(EditApp).use(i18n).component("result-card", ResultCard).component("vue-editor", VueEditor);
edit_app.config.globalProperties.$moment = moment;
edit_app.mount('#edit_app');