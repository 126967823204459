import L from 'leaflet';
import CONFIG from "./config.js";

export default {
    mapConfig: {
        GREEN: {
            color: "#7ED321",
            icon: "/static/v1/img/icon_GREEN.png",
            iconHovered: "/static/v1/img/icon_hovered_GREEN.png"
        },
        YELLOW: {
            color: "#FFD226",
            icon: "/static/v1/img/icon_YELLOW.png",
            iconHovered: "/static/v1/img/icon_hovered_YELLOW.png"
        },
        RED: {
            color: "#D0021B",
            icon: "/static/v1/img/icon_RED.png",
            iconHovered: "/static/v1/img/icon_hovered_RED.png"
        }
    },
    drawMap: function(mapId, center, addGeometry, moving) {
        // init map
        if (this.m) {
            this.m.remove();
        }
        this.m = L.map(mapId, {
            center: center,
            zoom: 13,
            zoomControl: false,
            dragging: moving,
            scrollWheelZoom: moving,
            attributionControl: false
        });
        var key = CONFIG.SMAPY_API_KEY
        L.tileLayer(`https://api.mapy.cz/v1/maptiles/aerial/256/{z}/{x}/{y}?apikey=${key}`, {
            minZoom: 0,
            maxZoom: 19,
        }).addTo(this.m);

        var attribution = L.control.attribution({ prefix: false })
        attribution.addAttribution('<a href="https://api.mapy.cz/copyright" target="_blank">&copy; Seznam.cz a.s. a další</a>');
        attribution.addTo(this.m);

        const LogoControl = L.Control.extend({
            options: {
              position: 'bottomleft',
            },

            onAdd: function (map) {
              const container = L.DomUtil.create('div');
              const link = L.DomUtil.create('a', '', container);

              link.setAttribute('href', 'http://mapy.cz/');
              link.setAttribute('target', '_blank');
              link.innerHTML = '<img src="https://api.mapy.cz/img/api/logo.svg" />';
              L.DomEvent.disableClickPropagation(link);

              return container;
            },
        });

        new LogoControl().addTo(this.m);

        setTimeout(function(){
            this.m.invalidateSize();

            if (addGeometry) {
                // draw polygons to the layer
                this.addGeometryToMap();
            }

            // sets proper zoom level and center of the map
            this.setZoomAndCenter();
        }.bind(this), 100);
    },
    setZoomAndCenter: function() {
        var myBounds = new L.LatLngBounds(this.allPoints);
        this.m.fitBounds(myBounds);
    },
    addGeometryToMap: function() {
        var geo_json = JSON.parse(this.action.geo_json);
        var geo_features = geo_json.features;

        this.allPoints = [];

        var color = this.mapConfig[this.action.severity].color;
        for (var feature of geo_features) {
            if (feature.geometry.type == "MultiPolygon") {
                var options = {
                    color: color,
                    outlineColor: color
                };

                for (var poly of feature.geometry.coordinates) {
                    var points = [];
                    for (var coord of poly) {
                        for (var point of coord) {
                            points.push(
                                [point[0], point[1]]
                            );
                            this.allPoints.push(
                                [point[0], point[1]]
                            );
                        }
                    }

                    var polygon = L.polygon(points, options);
                    polygon.addTo(this.m)
                }
            }

            if (feature.geometry.type == "Polygon") {
                var options = {
                    color: color,
                    outlineColor: color
                };
                var points = [];
                for (var coord of feature.geometry.coordinates) {
                    for (var point of coord) {
                        points.push([point[1], point[0]]);
                        this.allPoints.push(
                            [point[1], point[0]]
                        );
                    }
                }

                var polygon = L.polygon(points, options);
                polygon.addTo(this.m)
            }

            if (feature.geometry.type == "LineString") {
                var options = {
                    color: color,
                    width: 4,
                    outlineWidth: 0
                };

                var points = [];
                for (var point of feature.geometry.coordinates) {
                    points.push([point[1], point[0]]);
                    this.allPoints.push(
                        [point[1], point[0]]
                    );
                }

                var polyline = L.polyline(points, options);
                polyline.addTo(this.m)
            }

            if (feature.geometry.type == "MultiLineString") {
                var options = {
                    color: color,
                    width: 4,
                    outlineWidth: 0
                };

                for (var line of feature.geometry.coordinates) {
                    var points = [];
                    for (var point of line) {
                        points.push([point[1], point[0]]);
                        this.allPoints.push(
                            [point[1], point[0]]
                        );
                    }

                    var polyline = L.polyline(points, options);
                    polyline.addTo(this.m)
                }
            }

            if (feature.geometry.type == "Point") {
                var icon = this.getIcon(this.action, false);

                point = [feature.geometry.coordinates[1],
                    feature.geometry.coordinates[0]];
                this.allPoints.push(
                        feature.geometry.coordinates[1],
                        feature.geometry.coordinates[0]
                    );
                var marker = L.marker(point, {
                    icon: icon
                });
                marker.addTo(this.m)
            }
        }
    },
    getPoints: function(geoJson) {
        var geo_json = JSON.parse(geoJson);
        var geo_features = geo_json.features;

        var allPoints = [];

        for (var feature of geo_features) {
            if (feature.geometry.type == "MultiPolygon") {
                for (var poly of feature.geometry.coordinates) {
                    for (var coord of poly) {
                        for (var point of coord) {
                            allPoints.push(
                                [point[1], point[0]]
                            );
                        }
                    }
                }
            }

            if (feature.geometry.type == "Polygon") {
                for (var coord of feature.geometry.coordinates) {
                    for (var point of coord) {
                        allPoints.push(
                            [point[1], point[0]]
                        );
                    }
                }
            }

            if (feature.geometry.type == "LineString") {
                for (var point of feature.geometry.coordinates) {
                    allPoints.push(
                        [point[1], point[0]]
                    );
                }
            }

            if (feature.geometry.type == "MultiLineString") {
                for (var line of feature.geometry.coordinates) {
                    for (var point of line) {
                        allPoints.push(
                            [point[1], point[0]]
                        );
                    }
                }
            }

            if (feature.geometry.type == "Point") {
                this.allPoints.push(
                       [feature.geometry.coordinates[1],
                        feature.geometry.coordinates[0]]
                );
            }
        }

        return allPoints;
    },
    getIcon: function (action, hovered) {
        var src = null
        if (action.severity != null) {
            if (!hovered) {
                src = this.mapConfig[action.severity].icon;
            } else {
                src = this.mapConfig[action.severity].iconHovered;
            }
        } else {
            src = this.mapConfig["GREEN"].iconHovered;
        }
        var icon = L.icon({iconUrl: src});
        return icon;
    }
};
