const cs_msg = {
    severity: {
        RED: "Zdržení více než 30 minut",
        YELLOW: "Zdržení 10-30 minut",
        GREEN: "Zdržení do 10 minut"
    },
    map: {
        basic: "Obecná",
        outdoor: "Turistická",
        aerial: "Letecká"
    },
    states: {
        DONE: "Ukončené akce",
        ACTIVE: "Probíhající akce",
        FUTURE: "Budoucí akce"
    }
}


export default cs_msg;