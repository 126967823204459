
<script>
import axios from "axios";
import mapFunctions from "./map";
import CONFIG from "./config.js";
import L from 'leaflet';

const api = axios.create({
    baseURL: "/api/",
    withCredentials: true,
    xsrfCookieName: "csrftoken",
    xsrfHeaderName: "X-CSRFToken"
});

export default {
    delimiters: ["[[", "]]"],

// var result_card_similar = require("./result_card_similar.js");
    el: "#detail_app",
    data: function () {
        return {
            action: null,
            actions: [],
            actionId: null,
            map_holder_id: "main_map",
            zoom: 12,
            max: 0,
            ready: false,
            center: null,
            m: null,
            allPoints: [],
            isHovered: false,
            history: false
        }
    },
    created() {
        this.history = window.location.pathname.includes("history");
        this.actionId = actionId;
        this.getAction();
        this.drawMap = mapFunctions.drawMap;
        this.setZoomAndCenter = mapFunctions.setZoomAndCenter;
        this.addGeometryToMap = mapFunctions.addGeometryToMap;
        this.mapConfig = mapFunctions.mapConfig;
        this.getIcon = mapFunctions.getIcon;
    },
    watch: {
        action: function(val) {
            if (val != null) {
                this.drawMap(this.map_holder_id, [this.action.lat, this.action.lon], true, true);
                var key = CONFIG.SMAPY_API_KEY;
                var layer_outdoor = L.tileLayer(`https://api.mapy.cz/v1/maptiles/outdoor/256/{z}/{x}/{y}?apikey=${key}`, {
                    minZoom: 0,
                    maxZoom: 19,
                    attribution: '<a href="https://api.mapy.cz/copyright" target="_blank">&copy; Seznam.cz a.s. a další</a>',
                });
                var layer_basic = L.tileLayer(`https://api.mapy.cz/v1/maptiles/basic/256/{z}/{x}/{y}?apikey=${key}`, {
                    minZoom: 0,
                    maxZoom: 19,
                    attribution: '<a href="https://api.mapy.cz/copyright" target="_blank">&copy; Seznam.cz a.s. a další</a>',
                });
                var layer_aerial = L.tileLayer(`https://api.mapy.cz/v1/maptiles/aerial/256/{z}/{x}/{y}?apikey=${key}`, {
                    minZoom: 0,
                    maxZoom: 19,
                    attribution: '<a href="https://api.mapy.cz/copyright" target="_blank">&copy; Seznam.cz a.s. a další</a>',
                });
                var name_basic = this.$t("map.basic");
                var name_outdoor = this.$t("map.outdoor");
                var name_aerial = this.$t("map.aerial")
                var layers = {}
                layers[name_basic] = layer_basic;
                layers[name_outdoor] = layer_outdoor;
                layers[name_aerial] = layer_aerial;
                L.control.layers(layers).addTo(this.m);
                L.control.zoom().addTo(this.m);
            } else {
                this.ready = false;
            }
        }
    },
    methods: {
        download: function(url) {
            window.open("https://" + window.location.host + "/" + url, "_blank");
        },
        getAction: function() {
            const vm = this;
            var url = "/action/";
            if (this.history) {
                url += "history/";
            }
            url += actionId;
            api
                .get(url)
                .then(function(response) {
                    vm.action = response.data;
                    if (!vm.history) {
                        vm.getSimilarActions();
                    }
                })
                .catch(function(e) {
                    if (axios.isCancel(e)) {
                        console.log("Request canceled", e.message);
                    } else {
                        console.log("Actions retrieval failed");
                        console.log(e);
                    }
                });
        },
        getSimilarActions: function(reload, nowait) {
            const vm = this;

            api
                .get(
                    "/action/near?lat=" +
                        this.action.lat +
                        "&lon=" +
                        this.action.lon +
                        "&exclude_action_id=" +
                        this.action.id
                )
                .then(function(response) {
                    vm.actions = response.data.data;
                })
                .catch(function(e) {
                    if (axios.isCancel(e)) {
                        console.log("Request canceled", e.message);
                    } else {
                        console.log("Actions retrieval failed");
                        console.log(e);
                    }
                    vm.loading = false;
                });
        }
    }
};

</script>