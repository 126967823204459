import { createI18n } from 'vue-i18n'

import cs_msg from "./cs.js";

const i18n = createI18n({
    locale: "cs", // set locale
    warnHtmlInMessage: 'off',
    messages: {
        cs: cs_msg
    },
    legacy: true,
    pluralizationRules: {
        cs: function(choice, choicesLength) {
            if (choice === 0) {
                return 0;
            } else if (choice === 1) {
                return 1;
            } else if (choice >= 2 && choice <= 4) {
                return 2;
            }

            return choicesLength < 4 ? 2 : 3;
        }
    }
});

export default i18n;
