
<script>
import mapFunctions from "./map.js";
import axios from "axios";
import CONFIG from "./config.js";
import { toHandlers } from "vue";

const api = axios.create({
    baseURL: "/api/",
    withCredentials: true,
    xsrfCookieName: "csrftoken",
    xsrfHeaderName: "X-CSRFToken"
});
    // import Datepicker from "vuejs-datepicker";
    // import { en, cs } from "vuejs-datepicker/dist/locale";
    // import { Datetime } from "vue-datetime";
    // import "vue-datetime/dist/vue-datetime.css";
    // import { Settings } from "luxon";
export default {
    delimiters: ["[[", "]]"],
    data() {
        return {
            datepickerClass: "form-control",
            action: { lon: 50.0880, lat: 14.42076},
            actionId: null,
            map_holder_id: "edit_map",
            zoom: 12,
            max: 0,
            ready: false,
            center: null,
            m: null,
            allPoints: [],
            isHovered: false,
            lat: null,
            lon: null,
            geoJson: null,
            marker: null,
            originalLat: null,
            originalLon: null,
            description: null,
            limitations: null,
            investor: null,
            detours: null,
            street: null,
            loading: false,
            customToolbar: [
                ["bold", "italic", "underline", "strike"], // toggled buttons
                [{ list: "ordered" }, { list: "bullet" }],
                [{ 'indent': '-1' }, { 'indent': '+1' }],
                [{ size: ["small", false, "large", "huge"] }], // custom dropdown

                [{ color: [] }, { background: [] }], // dropdown with defaults from theme
                [{ align: [] }],
                ["link"]
            ],
            actionStart: null,
            actionStartTime: "00:00",
            actionEnd: null,
            actionEndTime: "23:59",
            valid: true,
            geoJsonValid: true,
            streetTimeout: null,
            duplicities: [],
            promoted: false,
            hidden: false,
            investor: null
        };
    },
    mounted() {
        this.actionId = actionId;
        this.investor = defaultInvestorName;
        this.drawMap = mapFunctions.drawMap;
        this.setZoomAndCenter = mapFunctions.setZoomAndCenter;
        this.addGeometryToMap = mapFunctions.addGeometryToMap;
        this.mapConfig = mapFunctions.mapConfig;
        this.getIcon = mapFunctions.getIcon;
        this.getPoints = mapFunctions.getPoints;
        this.getAction();
    },
    watch: {
        geoJson: function (val) {
            try {
                JSON.parse(val);
                this.valid = true;
                this.geoJsonValid = true;
            } catch (e) {
                this.valid = false;
                this.geoJsonValid = false;
            }
        },
        street: function (val) {
            this.getDuplicity();
        }
    },
    computed: {
        otherInvestor: function () {
            return !this.investor;
        }
    },
    methods: {
        getAction() {
            if (!actionId) {
                return
            }
            const vm = this;
            var url = "/action/";
            url += actionId;
            api
                .get(url)
                .then(function(response) {
                    vm.action = response.data;
                    if (!vm.history) {
                        vm.getSimilarActions();
                    }
                })
                .catch(function(e) {
                    if (axios.isCancel(e)) {
                        console.log("Request canceled", e.message);
                    } else {
                        console.log("Actions retrieval failed");
                        console.log(e);
                    }
                });
        },
        customFormatter(date) {
            return this.$moment(date).format("DD. MM. YYYY");
        },
        getDuplicity: function () {
            if (this.street) {
                const vm = this;
                vm.loading = true;
                if (this.streetTimeout) {
                    clearTimeout(this.streetTimeout);
                }

                this.streetTimeout = setTimeout(function () {
                    api
                        .get("/duplicity?limit=100&street=" + vm.street + "&action=" + actionId)
                        .then(function (response) {
                            vm.duplicities = response.data.data;
                        })
                        .catch(function (e) {
                            if (axios.isCancel(e)) {
                                console.log("Request canceled", e.message);
                            } else {
                                console.log("Actions retrieval failed");
                                console.log(e);
                            }
                        }).finally(function () {
                            vm.streetTimeout = null;
                            vm.loading = false;
                        });
                }, 700);
            } else {
                this.action = {};
            }
        },
        getAction: function () {
            if (actionId != "") {
                api
                    .get("/action/" + actionId)
                    .then(function (response) {
                        var data = response.data;
                        var start = data.start;
                        var end = data.end;
                        this.street = data.street;
                        this.description = data.description;
                        this.limitations = data.limitations;
                        this.detours = data.detours;
                        this.actionStart = start.split("T")[0];
                        this.actionStartTime = start.split("T")[1].slice(0, 5);
                        this.actionEnd = end.split("T")[0];
                        this.actionEndTime = end.split("T")[1].slice(0, 5);
                        this.geoJson = data.geo_json;
                        this.lat = data.lat;
                        this.lon = data.lon;
                        this.promoted = data.promoted;
                        this.hidden = data.hidden;
                        this.investor = data.investor;
                        this.action = data;
                        this.draw()
                    }.bind(this))
                    .catch(function (e) {
                        if (axios.isCancel(e)) {
                            console.log("Request canceled", e.message);
                        } else {
                            console.log("Actions retrieval failed");
                            console.log(e);
                        }
                    });
            } else {
                this.action = { lat: 50.0880, lon: 14.42076 };
                this.lat = 50.0880;
                this.lon = 14.42076;
                this.draw()
            }
        },
        addActionToMap: function () {
            var icon = this.getIcon(this.action, false);

            this.originalLat = this.action.lat;
            this.originalLon = this.action.lon;

            var center = [
                this.action.lat,
                this.action.lon
            ];

            var points = [];
            points.push(center);

            var bounds = new L.LatLngBounds(points);
            this.m.fitBounds(bounds);

            this.markerLayer = L.marker(center, {
                icon: icon,
                draggable: true
            }).addTo(this.m);
            this.markerLayer.on('dragend', function(event) {
                var latlng = event.target.getLatLng();
                this.lat = latlng.lat;
                this.lon = latlng.lng;
            }.bind(this));
            this.m.on('click', function (event) {
                var latlng = event.latlng;
                this.lat = latlng.lat;
                this.lon = latlng.lng;
                this.markerLayer.setLatLng(latlng);
            }.bind(this));
        },
        resetActionPosition: function () {
            this.markerLayer.setLatLng(new L.LatLng(this.originalLat, this.originalLon));

            var points = [];
            points.push(
                [this.originalLat, this.originalLon]
            );

            var bounds = new L.LatLngBounds(points);
            this.m.fitBounds(bounds);

            this.lat = this.originalLat;
            this.lon = this.originalLon;
        },
        centerActionPosition: function () {
            var bounds = new L.LatLngBounds(this.getPoints(this.geoJson));
            var center = bounds.getCenter()
            this.markerLayer.setLatLng(center);

            this.m.fitBounds(bounds);


            this.lon = center.lng;
            this.lat = center.lat;
        },
        draw: function () {
            if (this.action && this.action.lat && this.action.lon && this.lat && this.lon) {
                this.drawMap(this.map_holder_id, [this.lat, this.lon], true, true);
                var key = CONFIG.SMAPY_API_KEY;
                var layer_outdoor = L.tileLayer(`https://api.mapy.cz/v1/maptiles/outdoor/256/{z}/{x}/{y}?apikey=${key}`, {
                    minZoom: 0,
                    maxZoom: 19,
                    attribution: '<a href="https://api.mapy.cz/copyright" target="_blank">&copy; Seznam.cz a.s. a další</a>',
                });
                var layer_basic = L.tileLayer(`https://api.mapy.cz/v1/maptiles/basic/256/{z}/{x}/{y}?apikey=${key}`, {
                    minZoom: 0,
                    maxZoom: 19,
                    attribution: '<a href="https://api.mapy.cz/copyright" target="_blank">&copy; Seznam.cz a.s. a další</a>',
                });
                var layer_aerial = L.tileLayer(`https://api.mapy.cz/v1/maptiles/aerial/256/{z}/{x}/{y}?apikey=${key}`, {
                    minZoom: 0,
                    maxZoom: 19,
                    attribution: '<a href="https://api.mapy.cz/copyright" target="_blank">&copy; Seznam.cz a.s. a další</a>',
                });
                var name_basic = this.$t("map.basic");
                var name_outdoor = this.$t("map.outdoor");
                var name_aerial = this.$t("map.aerial")
                var layers = {}
                layers[name_basic] = layer_basic;
                layers[name_outdoor] = layer_outdoor;
                layers[name_aerial] = layer_aerial;
                L.control.layers(layers).addTo(this.m);
                L.control.zoom().addTo(this.m);
                this.addActionToMap();
            } else {
                this.ready = false;
            }
        }
    }
};
</script>